import { Injectable, Injector, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { Auth, API, Cache } from 'aws-amplify'

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void
}

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  private headers: any; 

  private defaultHeaders(): any {
    try {
      const { token } = Cache.getItem('federatedInfo')
      return {
        'X-VAC-google_id_token': token,
      }
    } catch {
      return {}
    }
  }

   getUserData = async ()  => {
     const userAsString = localStorage.getItem('user')
     if(!userAsString)
      return null
    const user = JSON.parse(userAsString)
    this.headers = {'email':  user.email}
    // this.headers = {'email':  'elisabeth.oudar@veolia.com'}

    const response = await this._get('/route_rh_get_salarie', [])
    if(response.codeRetour && response.codeRetour == '103'){
      this.router.navigate(['/not-authorized']);
    }
    return response
  }


  private _get(path: string, queryParameters: object) {
    const params = {
      headers: this.headers,
      queryStringParameters: queryParameters,
    }
    return API.get('route-rh-api', path, params)
  }

  constructor(private router: Router, private injector: Injector) {}
}