import { Component, ViewChild, ElementRef  } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment.local'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../core/services/user-login.service'

declare var window: any

@Component({
  selector: 'google-signin-btn',
  templateUrl: './google-signin-btn.component.html',
})
export class GoogleSigninBtnComponent {

  @ViewChild("signinGoogleBtn", {static: false}) signinGoogleBtn!: ElementRef;
  public userLoggedIn: boolean = false

  public googleInit() {
    window.google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (token: any) => {
        this.userLogin.onLoginSuccess(token)
      },
    })
    window.google.accounts.id.renderButton(
      this.signinGoogleBtn.nativeElement,
      { theme: "outline", size: "large" } 
    );
  }

  constructor(private userLogin: UserLoginService, private router:Router){
    
  }

  ngAfterViewInit() {
    this.userLogin.isAuthenticated(this)
  }

  isLoggedIn(_: any, loggedIn: boolean) {
    if (!loggedIn) {
      this.googleInit()
    }else{
      this.userLoggedIn = true
      this.router.navigate(['/demandes'])
    }
  }
  signOut(): void {
    window.google.accounts.id.revoke(environment.googleClientId)
    this.userLogin.onLogoutSuccess()
  }
}
