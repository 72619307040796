<!-- Menu haut-->
<div class="row">
    <div class="col-68p menu-topBottomBorder menu-left-background rounded">
        <!--Rectangle de rechere-->
        <div class="row pb-2">
            <div class="menu-subtitle-principal-font menu-subtitle-margin">Recherche</div>
        </div>
        <div class="row">
            <form id="filtreForm">
                <div class="form-group row mx-auto">

                    <div class="col-15p pr-0">
                        <label for="inputRegion" class="col-form-label menu-subtitle-font">Région</label>
                        <select id="inputRegion" class="form-control form-control-custom ">
                            <option value="all" selected>toutes</option>
                            <!-- <option *ngFor="let region of regions" [value]="region.codeRegion">{{region.libelleRegion}}</option> -->
                            <option value="03">03</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="11">11</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            </select>
                    </div>


                    <div class="col-20p pr-0 ">
                        <label for="inputTraiteCommune" class="col-form-label menu-subtitle-font ">Traité - commune</label>
                        <input id="inputTraiteCommune" minlength="6" maxlength="6" (click)="inputTraiteCommuneClick()" value="" type="text " class="form-control form-control-custom" maxlength="7" placeholder="xxx xxx" (keyup)="validOnEnter($event)">
                    </div>

                    <div class="col-3 pr-0 ">
                        <label for="inputCommune" class="col-form-label menu-subtitle-font ">Commune</label>
                        <input id="inputCommune" maxlength="100" value="" type="text " class="form-control form-control-custom " placeholder="Commune" (keyup)="validOnEnter($event)">
                    </div>


                    <div class="col-20p pr-0 ">
                        <label for="inputCodePostalInse" class="col-form-label menu-subtitle-font ">Code INSEE</label>
                        <input id="inputCodePostalInse" (click)="inputCodePostalInseClick()" value="" type="text " class="form-control form-control-custom " maxlength="5" placeholder="INSEE" onkeypress="return isNumber(event)" (keyup)="validOnEnter($event)">
                    </div>

                    <div class="col-20p pr-0 ">
                        <div class="btn-group-vertical w-100 ">
                            <button (click)="onClickReinitialisation()" type="button" class="btn btn-sm btn-custom-active mb-3 ">Réinitialiser le filtre</button>
                            <button (click)="onClickRechercher()" type="button" class="btn btn-sm btn-custom-active mb-3 ">Rechercher</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>

    <div class="col menu-topBottomBorder menu-right-background ">
        <!--Rectangle de documents-->
        <div class="row pb-2 ">
            <div class="menu-subtitle-principal-font menu-subtitle-margin ">Documents</div>
        </div>
        <div class="row ">
            <div class="col pr-2">
                <div class="btn-group-vertical w-100 ">
                    <label for="TarButtonInputFile" id="downloadTarButton" type="button" class="btn btn-sm btn-custom-inactive mb-2 px-1">Charger une fiche tarifaire DCA</label>
                    <input type="file" id="TarButtonInputFile" accept="application/pdf" style="display: none" (change)="onUploadFile('TAR', $event)" onclick="this.value=null;" />
                </div>
            </div>
        </div>
        <div class="row ">       
            <div class="col-40p pr-0 ">
                <div class="btn-group-vertical w-100 ">
                    <label for="RseButtonInputFile" id="downloadRseButton" type="button " class="btn btn-sm btn-custom-inactive mb-2 ">Charger un RSE</label>
                    <input type="file" id="RseButtonInputFile" accept="application/pdf" style="display: none" (change)="onUploadFile('RSE', $event)" onclick="this.value=null;" />
                    <label for="RsaButtonInputFile" id="downloadRsaButton" type="button " class="btn btn-sm btn-custom-inactive mb-2 ">Charger un RSA</label>
                    <input type="file" id="RsaButtonInputFile" accept="application/pdf" style="display: none" (change)="onUploadFile('RSA', $event)" onclick="this.value=null;" />
                </div>
            </div>
            <div class="col pl-2 pr-2 ">
                <div class="btn-group-vertical w-100 ">
                    <label for="AnxButtonInputFile" id="downloadDocButton" type="button " class="btn btn-sm btn-custom-inactive mb-2 px-1">Charger un document annexe</label>
                    <input type="file" id="AnxButtonInputFile" accept="application/pdf" style="display: none" (change)="onUploadFile('ANX', $event)" onclick="this.value=null;" />
                    <button (click)="removeDocButton()" id="removeDocButton" type="button " class="btn btn-sm btn-custom-inactive mb-2 ">Supprimer les documents</button>
                </div>
            </div>

        </div>

    </div>
</div>
<!-- alert message -->
<div class="row" id="alert-place">

</div>
<!-- Table -->
<div class="row ">
    <div class="table-responsive table-container ">
        <table class="table ">
            <thead>
                <tr>
                    <th>
                        <div class="checkbox">
                            <input (click)="onCheckboxTitleClick()" type="checkbox" class="inputTitle " id="inputTitle" name="partialCheck" />
                            <label for="inputTitle"></label>
                        </div>
                    </th>
                    <th>Région</th>
                    <th>Traité-commune</th>
                    <th>Code quartier</th>
                    <!-- <th>Code Postal</th> -->
                    <th>Code INSEE</th>
                    <th>Commune</th>
                    <th>RSA</th>
                    <th>RSE</th>
                    <th>Document annexe</th>
                    <th>Fiche tarifaire DCA</th>
                    <th>Date de mise à jour</th>
                </tr>
            </thead>
            <tbody *ngIf="(kitAbonnements !==undefined &&  kitAbonnements.getContents !==undefined && kitAbonnements.getContents.length !==0 ) else loading ">
                <tr *ngFor="let item of kitAbonnements.getContents; index as i ">
                    <td>
                        <div class="checkbox">
                            <input (click)="onCheckboxRowClick('checkboxRow-' + i)" type="checkbox" id="checkboxRow-{{i}}" />
                            <label for="checkboxRow-{{i}}"></label>
                        </div>
                    </td>
                    <!-- code region -->
                    <ng-container *ngIf="item.getCodeRegion !==undefined; else codeRegionEmptyTemplate ">
                        <td>{{item.codeRegion}}</td>
                    </ng-container>
                    <ng-template #codeRegionEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- traité commune -->
                    <ng-container *ngIf="item.getCodeTraite_commune !==undefined; else traiteCommuneEmptyTemplate ">
                        <td>{{item.getCodeTraite_commune}}</td>
                    </ng-container>
                    <ng-template #traiteCommuneEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- code quartier -->
                    <ng-container *ngIf="item.getIdComquar !==undefined; else codeQuartierEmptyTemplate ">
                        <td>{{item.idComquar}}</td>
                    </ng-container>
                    <ng-template #codeQuartierEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- code postal -->
                    <!-- <ng-container *ngIf="item.codePostal !==undefined; else codePostalEmptyTemplate ">
                        <td>{{item.codePostal}}</td>
                    </ng-container>
                    <ng-template #codePostalEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template> -->
                    <!-- code INSEE -->
                    <ng-container *ngIf="item.getCodeInsee !==undefined; else codeInseeEmptyTemplate ">
                        <td>{{item.codeInsee}}</td>
                    </ng-container>
                    <ng-template #codeInseeEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- commune -->
                    <ng-container *ngIf="item.getLibelleCommuneInsee !==undefined; else communeEmptyTemplate ">
                        <td>{{item.libelleCommuneInsee}}</td>
                    </ng-container>
                    <ng-template #communeEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- RSA -->
                    <ng-container *ngIf="item.getRsa !==undefined; else rsaEmptyTemplate ">
                        <td>
                            <img (click)="onDownloadFile(item.getIdComquar, item.getRsa.typeFichier)" class="p-1 pointer" src="/assets/img/download.png " alt="image ">
                            <img (click)="onDeleteFile(item.getIdComquar, item.getRsa.typeFichier)" class="p-1 pointer" src="/assets/img/delete.png " alt="image ">
                        </td>
                    </ng-container>
                    <ng-template #rsaEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- RSE -->
                    <ng-container *ngIf="item.getRse !==undefined; else rseEmptyTemplate ">
                        <td>
                            <img (click)="onDownloadFile(item.getIdComquar, item.getRse.typeFichier)" class="p-1 pointer" src="/assets/img/download.png " alt="image ">
                            <img (click)="onDeleteFile(item.getIdComquar, item.getRse.typeFichier)" class="p-1 pointer" src="/assets/img/delete.png " alt="image ">
                        </td>
                    </ng-container>
                    <ng-template #rseEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- document annexe-->
                    <ng-container *ngIf="item.getDocumentAnnexe !==undefined; else documentAnnexeEmptyTemplate ">
                        <td>
                            <img (click)="onDownloadFile(item.getIdComquar, item.getDocumentAnnexe.typeFichier)" class="p-1 pointer" src="/assets/img/download.png " alt="image ">
                            <img (click)="onDeleteFile(item.getIdComquar, item.getDocumentAnnexe.typeFichier)" class="p-1 pointer" src="/assets/img/delete.png " alt="image ">
                        </td>
                    </ng-container>
                    <ng-template #documentAnnexeEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- fiche tarifaire DCA-->
                    <ng-container *ngIf="item.getFicheTarifaireDCA !==undefined; else ficheTarifaireDCAEmptyTemplate ">
                        <td>
                            <img (click)="onDownloadFile(item.getIdComquar, item.getFicheTarifaireDCA.typeFichier)" class="p-1 pointer" src="/assets/img/download.png " alt="image ">
                            <img (click)="onDeleteFile(item.getIdComquar, item.getFicheTarifaireDCA.typeFichier)" class="p-1 pointer" src="/assets/img/delete.png " alt="image ">
                        </td>
                    </ng-container>
                    <ng-template #ficheTarifaireDCAEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>
                    <!-- date MAJ -->
                    <ng-container *ngIf="item.getDateMAJ !==undefined; else dateEmptyTemplate ">
                        <td>{{item.getDateMAJ | date:'dd/MM/yyyy'}}</td>
                    </ng-container>
                    <ng-template #dateEmptyTemplate>
                        <td>&oslash;</td>
                    </ng-template>

                </tr>
            </tbody>
            <ng-template #loading>
                <tbody>
                    <tr>
                        <ng-container *ngIf="kitAbonnements===undefined; else emptyContent ">

                            <td colspan="10">
                                <div class="loader mx-auto "></div>
                            </td>
                        </ng-container>
                        <ng-template #emptyContent>

                            <td colspan="10 ">
                                <div class="mx-auto ">No content to display</div>
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
            </ng-template>
        </table>

        <div *ngIf="(kitAbonnements !==undefined) " class=" pagination ">
            <span *ngIf="kitAbonnements.getContents.length !==0"> {{kitAbonnements.getFirstNumber}}-{{kitAbonnements.getLastNumber}} sur {{kitAbonnements.getTotalSize}}</span>
            <span *ngIf="kitAbonnements.getContents.length ===0"> 0 - 0 sur 0</span>
            <span class="pagination-spin">
                <a  id="previousButton" class="page-item" (click)="paginationBackClick()" aria-label="Previous ">
                    <span aria-hidden="true ">&lt;</span>
            </a>
            </span>

            <span class="pagination-spin">
                <a id="nextButton" class="page-item" (click)="paginationNextClick()" aria-label="Next ">
                 <span aria-hidden="true ">&gt;</span>
            </a>
            </span>
        </div>

    </div>


</div>