import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
 import { Observable, Subscription } from 'rxjs';
 import { map, tap, catchError } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { UserLoginService } from '../core/services/user-login.service';

//import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Amplify, API, Auth } from 'aws-amplify';
import { userInfo } from 'os';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  
  constructor(
    public userLogin: UserLoginService,
    public router: Router
  ){ }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log(' ==> on guards state')
        return this.userLogin.isLoggedIn()        
        .pipe(
          tap(loggedIn => {
              console.log('==> loggedIn : ', loggedIn)
            if (!loggedIn) {
                console.log('==> guard redirect to home ')
              this.router.navigate(['/home']);
            }
          })
        );       
  }
}