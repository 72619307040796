import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment.local'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../core/services/user-login.service'

declare var window: any

@Component({
  selector: 'google-signin',
  templateUrl: './google-signin.component.html',
  styleUrls: ['./google-signin.component.scss']
})
export class GoogleSigninComponent {
  public googleInit() {
    window.google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (token: any) => {
        this.userLogin.onLoginSuccess(token)
      },
    })
    
    window.google.accounts.id.prompt()
  }

  constructor(private userLogin: UserLoginService) {
    userLogin.isAuthenticated(this)
  }

  isLoggedIn(_: any, loggedIn: boolean) {
    if (!loggedIn) {
      this.googleInit()
    }
  }
}

@Component({
  selector: 'google-signout',
  templateUrl: './google-signout.component.html',
})
export class LogoutComponent implements OnInit, LoggedInCallback {
  constructor(public router: Router, public userService: UserLoginService) {
    this.userService.isAuthenticated(this)
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.navigate(['/demandes'])
    }
  }

  ngOnInit() {}

  signOut(): void {
    //window.google.accounts.id.revoke(environment.googleClientId)
    this.userService.onLogoutSuccess()
    .then(data => this.router.navigate(['/home']))
  }
}