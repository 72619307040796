<!-- Toolbar 
<caaks-header></caaks-header>
-->
<div class="text-center">
    <google-signin-btn class="text-center"></google-signin-btn>
  </div>


<!--
<mat-sidenav-container class="example-container" autosize>

    <div fxFlexAlign="center" class="example-sidenav-content">
        <google-signin-btn></google-signin-btn>
  
       <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
-->








<!-- Footer -->