export class PdfFilesDTO {
    private nomFichier: string;
    private typeFichier: string;
    private leFichier: Uint8Array;
    private majLe: Date;
    private creeLe: Date;
    private majPar: number;
    private url: string;

    constructor() {

    }

    public loadFromJson = function loadFromJson(data: any) {
        if (data != undefined) {
            this.nomFichier = data.nomFichier;
            this.typeFichier = data.typeFichier;
            this.leFichier = data.leFichier;
            this.majLe = data.majLe;
            this.creeLe = data.creeLe;
            this.majPar = data.majPar;
            this.url = data.url;
        }
    }

    public copy = function (): PdfFilesDTO {
        var doc: PdfFilesDTO = new PdfFilesDTO();
        doc.nomFichier = this.nomFichier;
        doc.typeFichier = this.typeFichier;
        doc.leFichier = this.leFichier;
        doc.majLe = this.majLe;
        doc.creeLe = this.creeLe;
        doc.majPar = this.majPar;
        doc.url = this.url;

        return doc;
    }

    get getNomFichier(): string {
        return this.nomFichier;
    }

    set setNomFichier(nomFichier: string) {
        this.nomFichier = nomFichier;
    }

    get getTypeFichier(): string {
        return this.typeFichier;
    }

    set setTypeFichiern(typeFichier: string) {
        this.typeFichier = typeFichier;
    }

    get getLeFichier(): Uint8Array {
        return this.leFichier;
    }

    set setLeFichier(leFichier: Uint8Array) {
        this.leFichier = leFichier;
    }

    get getMajLe(): Date {
        return this.majLe;
    }

    set setMajLe(majLe: Date) {
        this.majLe = majLe;
    }

    get getCreeLe(): Date {
        return this.creeLe;
    }

    set setCreeLe(creeLe: Date) {
        this.creeLe = creeLe;
    }

    get getMajPar(): number {
        return this.majPar;
    }

    set setMajPar(majPar: number) {
        this.majPar = majPar;
    }

    get setUrl(): string {
        return this.url;
    }

    set getUrl(url: string) {
        this.url = url;
    }


}