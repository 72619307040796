
export const environment = {
  production: false,
  environment: 'Recette',
  API_URL: 'https://gbu6jaiz06.execute-api.eu-west-1.amazonaws.com/rec',
  apiBackofficeName : 'ael_api_rec',
  //gatewayBaseUrl:'https://0or8fglhc1.execute-api.eu-west-1.amazonaws.com/rec',
  // Global region for AWS Amplify configuration
  globalRegion: 'eu-west-1',

  // Identity region to Ireland
  identityRegion: 'eu-west-1',

  // API region for URL signing
  apiRegion: 'eu-west-1',

  // DEV environment identifiers (environment deployed in AWS)
  //RL4ptlnIu-78SLUNXohFAgL0
  googleClientId: '85016409298-8mtgtlmgkjjv9081rob2gnh86b46i0dl.apps.googleusercontent.com',
  identityPoolId: 'eu-west-1:626ca802-3757-4c1a-99b9-f342973aa523',
 // identityPoolId: 'eu-west-1:df90d71e-e99d-4d4d-8872-1a2f94d57061',
 // API_URL: 'https://wasadyehu7.execute-api.eu-west-1.amazonaws.com/dev'
 build: "1.0.8",
 commit: "1.0.2",

};
