import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tablevaleur',
  templateUrl: './tablevaleur.component.html',
  styleUrls: ['./tablevaleur.component.scss']
})
export class TablevaleurComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
