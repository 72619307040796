import { Injectable, OnInit } from "@angular/core";
import { API, Auth } from 'aws-amplify';
import { Cache } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { KitAbonnement } from '../models/kitAbonnement';
import { SelectorInsee } from '../models/selectorInsee';
import { UserLoginService } from '../core/services/user-login.service';
import { environment } from "../../environments/environment.local";

@Injectable()
export class CloudEndpointService  {

  constructor(private userloginservice: UserLoginService ){

  }

  //observable kitAbo
  private kitAbonementSubject  = new BehaviorSubject<KitAbonnement>(undefined);
  currentKitAbonnement = this.kitAbonementSubject.asObservable();

  changeKitAbo(kitAbo: KitAbonnement) {
    this.kitAbonementSubject.next(kitAbo);
  }

  // observable selectorInsee
  private selectorInseeSubject  = new BehaviorSubject<SelectorInsee>(undefined);
  currentSelectorInsee = this.selectorInseeSubject.asObservable();

  changeSelectorInsee(selectorInsee: SelectorInsee) {
    this.selectorInseeSubject.next(selectorInsee);
  }

  // observable selectorTab
  private selectorTabSubject  = new BehaviorSubject<Array<any>>(undefined);
  currentSelectorTab = this.selectorTabSubject.asObservable();

  changeSelectorTab(selectorTab: Array<any>) {
    this.selectorTabSubject.next(selectorTab);
  }

    /**
     * Build a custom header containing Google JWT token in order to verify user info if needed.
     */
    private defaultHeaders(): any {
      //let token=this.userloginservice.getTokenPromise();
      const { token } = Cache.getItem('federatedInfo')
      return {
            "x-backoffice-token": token
           
        }
    }

    // get documents all by page
    public async getKitAbonnements(page: number) : Promise<KitAbonnement> {
      
      const apiName = environment.apiBackofficeName;
      const path = '/commune/bySearchPaged'; 
      const myInit = { // OPTIONAL
         headers: this.defaultHeaders(),
         // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
         queryStringParameters: {  // OPTIONAL
            page: page,
            codeRegion : 'all'
          },
      };
      return await API.get(apiName, path, myInit);
      
    }

    // get documents all by page and search selector
    public async getKitAbonnementsBySearch(page: number, search: SelectorInsee) : Promise<KitAbonnement> {
      const apiName = environment.apiBackofficeName;
      const path = '/commune/bySearchPaged?page=' + page + search.queryString
      const myInit = {
        headers: this.defaultHeaders(),
      };
      return await API.get(apiName, path, myInit);
      
    }


    // get a document by comquar and filetype
    public async getDocument(idComquar, typeFichier) : Promise<any> { 
      
      const apiName = environment.apiBackofficeName;
      const path = '/document/' + idComquar + "/" + typeFichier;
      const myInit = {
        headers: this.defaultHeaders(),
      };
      return await API.get(apiName, path, myInit);
      
    }

    

    // get a document by comquar and filetype
    public async deleteDocument(idComquars, typeFichier) : Promise<any> { 
      
      const apiName = environment.apiBackofficeName;
      const path ='/document/' + idComquars + '/' + typeFichier;
      const myInit = {
        headers: this.defaultHeaders(),
      };
      return await API.del(apiName, path, myInit);
      
    }

    
    // get presignedURL to dowloand file
    public async getPresignedUrlToUploadFile() : Promise<any> { 
      
      const apiName = environment.apiBackofficeName;
      const path = '/document/upload/presignedUrl';
      const myInit = {
        headers: this.defaultHeaders(),
      };
      return await API.get(apiName, path, myInit);
      
    }

    // put file to presignedURL to dowloand file
    public async putFileToPresignedURL(presignedURL, form) : Promise<any> { 
      
      const apiName = environment.apiBackofficeName;
      const path = presignedURL;
      const myInit = {
        headers: this.defaultHeaders(),
      };
      return await API.get(apiName, path, myInit);
      
    }
    
    // Post file to comquar
    public async postFileUpload(idsComquar: Array<String>, typeFichier, fileName) : Promise<any> { 
      const apiName = environment.apiBackofficeName;
      const path = '/document/upload';
      const myInit = {
        headers: this.defaultHeaders(),
        body: {
          idsComquar: idsComquar,
          typeFichier: typeFichier,
          fileName : fileName
        },
      };
      return await API.post(apiName, path, myInit);
    }


}