import { InseeDTO } from './inseeDTO';

export class KitAbonnement {
  private content: Array<InseeDTO>;
  private totalSize: number;
  private totalPages: number;
  private pageNumber: number;
  private numberOfElements: number;
  private empty: boolean;
  private size: number;
  private offset: number;

  constructor() {
    this.content = new Array();
  }

  public loadFromJson = function loadFromJson(data: any) {
    if (data != undefined) {
      this.totalSize = data.totalSize;
      this.totalPages = data.totalPages;
      this.pageNumber = data.pageNumber;
      this.numberOfElements = data.numberOfElements;
      this.empty = data.empty;
      this.size = data.size;
      this.offset = data.offset;
      // fill contents
      if (data.content != undefined) {
        Object.entries(data.content).forEach(([key, value]) => {
          var inseeDTO = new InseeDTO();
          inseeDTO.loadFromJson(value);
          this.content.push(inseeDTO);
        });
      }
    }
  }

  public get copy(): KitAbonnement {
    var kitAbo: KitAbonnement = new KitAbonnement();
    kitAbo.totalSize = this.totalSize;
    kitAbo.totalPages = this.totalPages;
    kitAbo.pageNumber = this.pageNumber;
    kitAbo.numberOfElements = this.numberOfElements;
    kitAbo.empty = this.empty;
    kitAbo.totalPages = this.totalPages;
    kitAbo.size = this.size;
    kitAbo.offset = this.offset;
    for (let item of this.content.values()) {
      kitAbo.content.push(item.copy());
    }
    return kitAbo;
  }

  public get getContents(): Array<InseeDTO> {
    return this.content;
  }
  public set setContents(value: Array<InseeDTO>) {
    this.content = value;
  }


  public get getTotalSize(): number {
    return this.totalSize;
  }
  public set setTotalSize(value: number) {
    this.totalSize = value;
  }

  public get getTotalPage(): number {
    return this.totalPages;
  }
  public set setTotalPage(value: number) {
    this.totalPages = value;
  }

  public get getPageNumber(): number {
    return this.pageNumber;
  }
  public set setPageNumber(value: number) {
    this.pageNumber = value;
  }

  public get getNumberOfElements(): number {
    return this.numberOfElements;
  }
  public set setNumberOfElements(value: number) {
    this.numberOfElements = value;
  }

  public get getEmpty(): boolean {
    return this.empty;
  }
  public set setEmpty(value: boolean) {
    this.empty = value;
  }

  public get getSize(): number {
    return this.size;
  }
  public set setSize(value: number) {
    this.size = value;
  }

  public get getOffset(): number {
    return this.offset;
  }
  public set setOffset(value: number) {
    this.offset = value;
  }

  public get getFirstNumber(): number {
    if (this.totalPages > 1) {
      return this.getLastNumber + 1 - this.size;
    }
    return 1;
  }

  public get getLastNumber(): number {
    if (this.totalPages > 1) {
      return (this.pageNumber + 1) * this.size;
    }
    return 1;
  }

  public deleteFile(idComquar, typeFichier) {
    this.content.forEach((item, index) => {
      if (item.getIdComquar === idComquar) {
        item.deleteFile(typeFichier);
      }
    });
  }
}