<router-outlet></router-outlet>

<!-- buil infos -->
<hr class="hr" />

<div class="text-center">
    <button class="btn btn-primary" (click)="logout()">Déconnexion</button>
</div>

<br/>

<div class="row width-100">
    
    <!-- Button trigger modal -->
    
    <button type="button" class="btn btn-custom-footer align-baseline ml-auto" data-toggle="modal" data-target="#buildInfos">
        Information de build
  </button>

    <!-- Modal -->
    <div class="modal fade" id="buildInfos" tabindex="-1" aria-labelledby="buildInfos" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel ">Informations de build</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true ">&times;</span>
                 </button>
                </div>
                <div class="modal-body ">
                    <p>Numéro de build : {{build}}</p>
                    <p>Numéro de commit : {{commit}}</p>
                </div>

            </div>
        </div>
    </div>