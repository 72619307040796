import { Component  } from '@angular/core';

declare var $: any;

@Component({
    selector: 'not-authorized',
    templateUrl: './not-authorized.component.html',
    styleUrls: ['./not-authorized.component.scss']
})

export class NotAuthorizedComponent {
    title: string = 'GAELLE'

    constructor() { 
                 
    }

}
