import { from } from 'rxjs';
import { fileURLToPath } from 'url';
import { PdfFilesDTO } from './pdfFilesDTO';
export class InseeDTO {
    private idComquar: string;
    private codeRegion: string;
    private codeInsee: string;
    private libelleCommuneInsee: string;
    private codeActiviteContrat: string;
    private codeTraiteGn: string;
    private codeCommuneGn: string;
    private documents: Array<PdfFilesDTO>;
    private codeTraite_commune: string;

    constructor() {
        this.documents = new Array();
    }

    public loadFromJson = function loadFromJson(data: any) {
        if (data != undefined) {
            this.idComquar = data.idComquar;
            this.codeRegion = data.codeRegion;
            this.codeInsee = data.codeInsee;
            this.libelleCommuneInsee = data.libelleCommuneInsee;
            this.codeActiviteContrat = data.codeActiviteContrat;
            this.codeTraiteGn = data.codeTraiteGn;
            this.codeCommuneGn = data.codeCommuneGn;
            // fill documents
            if (data.documents != undefined) {
                Object.entries(data.documents).forEach(([key, value]) => {
                    var document = new PdfFilesDTO();
                    document.loadFromJson(value);
                    this.documents.push(document);
                });
            }
        }
    }

    public copy = function (): InseeDTO {
        var insee: InseeDTO = new InseeDTO();
        insee.idComquar = this.idComquar;
        insee.codeRegion = this.codeRegion;
        insee.codeInsee = this.codeInsee;
        insee.libelleCommuneInsee = this.libelleCommuneInsee;
        insee.codeActiviteContrat = this.codeActiviteContrat;
        insee.codeTraiteGn = this.codeTraiteGn;
        insee.codeCommuneGn = this.codeCommuneGn;
        insee.codeTraite_commune = this._codeTraitecommune;

        for (let item of this.documents.values()) {
            insee.documents.push(item.copy());
        }
        return insee;
    }

    public get getIdComquar(): string {
        return this.idComquar;
    }

    public set setIdComquar(idComquar: string) {
        this.idComquar = idComquar;
    }

    public get getCodeRegion(): string {
        return this.codeRegion;
    }

    public set setCodeRegion(codeRegion: string) {
        this.codeRegion = codeRegion;
    }

    public get getCodeInsee(): string {
        return this.codeInsee;
    }

    public set setCodeInsee(codeInsee: string) {
        this.codeInsee = codeInsee;
    }

    public get getLibelleCommuneInsee(): string {
        return this.libelleCommuneInsee;
    }

    public set setLibelleCommuneInsee(libelleCommuneInsee: string) {
        this.libelleCommuneInsee = libelleCommuneInsee;
    }

    public get getCodeActiviteContrat(): string {
        return this.codeActiviteContrat;
    }

    public set setCodeActiviteContrat(codeActiviteContrat: string) {
        this.codeActiviteContrat = codeActiviteContrat;
    }

    public get getCodeTraiteGn(): string {
        return this.codeTraiteGn;
    }

    public set setCodeTraiteGn(codeTraiteGn: string) {
        this.codeTraiteGn = codeTraiteGn;
    }

    public get getCodeCommuneGn(): string {
        return this.codeCommuneGn;
    }

    public set setCodeCommuneGn(codeCommuneGn: string) {
        this.codeCommuneGn = codeCommuneGn;
    }

    public get getDocuments(): Array<PdfFilesDTO> {
        return this.documents;
    }

    public set setDocuments(documents: Array<PdfFilesDTO>) {
        this.documents = documents;
    }

    public get getCodeTraite_commune(): string {
        if (this.codeTraiteGn == undefined || this.codeCommuneGn == undefined) return undefined;
        return this.codeTraiteGn + " " + this.codeCommuneGn;
    }

    public set setCodeTraite_commune(value: string) {
        this.codeTraite_commune = value;
    }

    public get getRse(): PdfFilesDTO {
        for (let item of this.documents.values()) {
            if (item.getTypeFichier == "RSE") {
                return item;
            }
        }
        return undefined;
    }

    public get getRsa(): PdfFilesDTO {
        for (let item of this.documents.values()) {
            if (item.getTypeFichier == "RSA") {
                return item;
            }
        }
        return undefined;
    }

    public get getDocumentAnnexe(): PdfFilesDTO {
        for (let item of this.documents.values()) {
            if (item.getTypeFichier == "ANX") {
                return item;
            }
        }
        return undefined;
    }

    public get getFicheTarifaireDCA(): PdfFilesDTO {
        for (let item of this.documents.values()) {
            if (item.getTypeFichier == "TAR") {
                return item;
            }
        }
        return undefined;
    }

    public get getDateMAJ(): Date {
        var date: Date = undefined
        return this.documents.map(function (e) { return e.getMajLe }).sort().reverse()[0]

    }

    public deleteFile(typeFichier) {
        this.documents.forEach((item, index) => {
            if (item.getTypeFichier === typeFichier) this.documents.splice(index, 1);
        });

    }

    private compareDate(date1: Date, date2: Date): Date {
        if (date1.getTime > date2.getTime) {
            return date1;
        }
        return date2;
    }

}