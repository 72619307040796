import { environment } from "../../environments/environment.local";
import { Injectable } from "@angular/core";
import { Amplify, API, Auth } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class AwsService {

    constructor() {
    } 

  initAmplify() {
    Auth.configure({
           identityPoolId: environment.identityPoolId,
            region: environment.globalRegion,
            identityPoolRegion: environment.identityRegion,
            mandatorySignIn: true,
        })
        API.configure({
            endpoints: [
                {
                    name: environment.apiBackofficeName,
                    endpoint: environment.API_URL,
                    region: environment.apiRegion
                }
            ]
        })
        Amplify.Logger.LOG_LEVEL = 'DEBUG';
        // Amplify.configure({
        //     Auth: {
        //         identityPoolId: environment.identityPoolId,
        //         region: environment.globalRegion,
        //         identityPoolRegion: environment.identityRegion,
        //         mandatorySignIn: true,
        //     },
        //     API: {
        //         endpoints: [
        //             {
        //                 name: 'route-rh-api',
        //                 endpoint: environment.apiEndpoint,
        //                 region: environment.apiRegion
        //             }
        //         ]
        //     }
        // });
  }

}
